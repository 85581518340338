<template>
  <div class="container">
    <iframe :src="link" frameborder="0" marginwidth=0 marginheight=0 class="iframe"></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      origin: window.location.origin,
    }
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
    }),
    link() {
      return origin + process.env.VUE_APP_DRAGON + '?token=' + this.infos.token + '&server_id=' + this.$store.state.serverid + '&language=' + (this.$storage.get("locale") ? this.$storage.get("locale") : 'tw')
    }
  },
  created() {
    this.$store.dispatch("Get_infos");
  },
  mounted() {
    console.log("link", this.link);
  }
}
</script>

<style>
.container {
  padding-top: 58px;
}
.container,
.iframe {
  width: 100vw;
  min-height: calc(100vh - 60px);
}
</style>
